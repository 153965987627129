<template>
  <div>
    <TTableAdvance
      :items="transactions"
      :fields="fields"
      :store="store"
      @click-clear-filter="clearFilter"
      resource=""
      reloadable
      @click-reload="fetch"
      noClickRow
    >
      <template #append-actions="{ item }">
        <CCol>
          <TButtonEdit variant="outline" @click="clickEdit(item)" />
        </CCol>
      </template>
      <template #type_id="{ item }">
        <td class="text-nowrap">
          <SMessageTransactionType :value="item.type_id" />
        </td>
      </template>
      <template #amount="{ item }">
        <td>
          <TMessageMoney
            :color="checkCredit(item) ? 'danger' : ''"
            :amount="item.amount"
            :currency="item.currency_id"
          />
        </td>
      </template>
      <template #code_ref="{ item }">
        <td>
          <TMessage :content="item.code_ref" noTranslate />
        </td>
      </template>
      <template #prepared_by_id="{ item }">
        <td>
          <TLink
            :to="lodash.getReferenceLink('user', item.prepared_by_id)"
            :content="
              item.prepared_by ? item.prepared_by.name : item.prepared_by_id
            "
          />
        </td>
      </template>
      <template #transactionable_id="{ item }">
        <td>
          <TLink
            v-if="item.object"
            class="text-truncate-1"
            :content="item.object.name"
            :to="lodash.getReferenceLink(
              `${
                item.transactionable_type == 'user' ? 'customer' : 'supplier'
              }`,
              `${item.wallet.id}`
            )"
          />
          <TMessageNotFound v-if="!item.transactionable_type" />
        </td>
      </template>
      <template #voucher_date="{ item }">
        <td>
          <TMessageDateTime :content="item.voucher_date" dateOnly />
        </td>
      </template>
      <template #description="{ item }">
        <td>
          <TMessage :content="item.description" noTranslate />
        </td>
      </template>
      <template #card_id="{ item }">
        <td>
          <SMessageCard v-if="item.card" :value="item.card" />
          <TMessageNotFound v-else />
        </td>
      </template>
      <template #credit_id="{ item }">
        <td>
          <SMessageAccount :value="item.credit_id" />
        </td>
      </template>
      <template #debit_id="{ item }">
        <td>
          <SMessageAccount :value="item.debit_id" />
        </td>
      </template>
      <template #updated_at="{ item }">
        <td>
          <TMessageDateTime :content="item.updated_at" small />
        </td>
      </template>
      <template #id-filter>
        <TInputText :value.sync="filter.id" @update:value="filterChange" />
      </template>
      <template #code_ref-filter>
        <TInputText
          :value.sync="filter.code_ref"
          @update:value="filterChange"
        />
      </template>
      <template #transactionable_id-filter>
        <SSelectRecipient
          :value.sync="filter.transactionable_id"
          @change="filterChange"
        />
      </template>
      <template #prepared_by_id-filter>
        <SSelectCustomer
          :value.sync="filter.prepared_by_id"
          @change="filterChange"
          noCustomLabel
          prependAll
        />
      </template>
      <template #credit_id-filter>
        <SSelectAccount
          :value.sync="filter.credit_id"
          @change="filterChange"
          :prepend="[{ id: '', name: $t('All') }]"
          noFetchStore
        />
      </template>
      <template #debit_id-filter>
        <SSelectAccount
          :value.sync="filter.debit_id"
          @change="filterChange"
          :prepend="[{ id: '', name: $t('All') }]"
          noFetchStore
        />
      </template>
      <template #type_id-filter>
        <SSelectTransactionType
          :value.sync="filter.type_id"
          @change="filterChange"
        />
      </template>
      <template #voucher_date-filter>
        <TInputDateTimeRange
          :value.sync="voucherDateRange"
          @update:value="setVoucherDateFilter"
        />
      </template>
      <template #updated_at-filter>
        <TInputDateTimeRange
          :value.sync="dateRange"
          @update:value="setUpdatedAtFilter"
        />
      </template>
      <template #card_id-filter>
        <SSelectCard
          :value.sync="filter.card_id"
          @change="filterChange"
          prependAll
        />
      </template>
    </TTableAdvance>
    <ModalTransaction :show.sync="showModalTransaction" :id="detail.id" />
  </div>
</template>

<script>
import ModalTransaction from "./ModalTransaction.vue";

export default {
  components: {
    ModalTransaction,
  },
  props: {
    store: {
      type: String,
      required: true,
    },
    account_id: {
      type: String,
      required: true,
    },
    default_filter: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px" },
        {
          key: "voucher_date",
          label: "Voucher date",
          _style: "width: 150px",
          sorter: true,
        },
        { key: "code_ref", label: "Ref code", _classes: "text-nowrap" },
        {
          key: "type_id",
          label: "Transaction type",
          _classes: "text-nowrap",
        },
        { key: "amount", label: "Amount", sorter: true },
        {
          key: "description",
          label: "Description",
          _style: "min-width: 250px",
        },
        {
          key: "transactionable_id",
          label: "Object",
          _classes: "",
          _style: "width: 240px; min-width: 240px",
        },
        {
          key: "prepared_by_id",
          label: "Executor",
          _classes: "text-nowrap",
          _style: "width: 200px; min-width: 200px",
        },
        {
          key: "debit_id",
          label: "Debit",
          _classes: "",
          _style: "width: 200px; min-width: 200px",
        },
        {
          key: "credit_id",
          label: "Credit",
          _classes: "",
          _style: "width: 200px; min-width: 200px",
        },
        {
          key: "card_id",
          label: "Card",
          _classes: "text-nowrap",
          _style: "width: 200px; min-width: 200px",
        },
        {
          key: "updated_at",
          sorter: true,
          label: "Updated at",
          _style: "width: 150px",
        },
      ],
      filter: {},
      filter_between: {},
      dateRange: {
        start: null,
        end: null,
      },
      voucherDateRange: {
        start: null,
        end: null,
      },
      showModalTransaction: false,
    };
  },
  watch: {
    default_filter: {
      immediate: true,
      handler(val, olVal) {
        if (!this.lodash.isEqual(val, olVal)) this.filterChange();
      },
    },
  },
  computed: {
    transactions() {
      return this.$store.getters[this.store + ".list"];
    },
    detail() {
      return this.$store.getters[this.store + ".detail"];
    },
  },
  methods: {
    fetch() {
      this.$store.dispatch(this.store + ".fetch");
    },
    filterChange() {
      const filterList = this.lodash.mapKeys(
        this.filter,
        function (value, key) {
          return `filter[${key}]`;
        }
      );
      const filterBetween = this.lodash.mapKeys(
        this.filter_between,
        function (value, key) {
          return `filter_between[${key}]`;
        }
      );
      const filterFields = this.lodash.pickBy({
        ...filterList,
        ...filterBetween,
        ...this.default_filter,
      });
      this.$store.dispatch(this.store + ".apply-query", filterFields);
    },
    setUpdatedAtFilter({ start, end }) {
      this.filter_between["updated_at"] = `${start},${end}`;
      this.filterChange();
    },
    setVoucherDateFilter({ start, end }) {
      this.filter_between["voucher_date"] = `${start},${end}`;
      this.filterChange();
    },
    clearFilter() {
      this.filter = {};
      this.filter_between = {};
      this.dateRange = {
        start: null,
        end: null,
      };
      this.voucherDateRange = {
        start: null,
        end: null,
      };
      this.filterChange();
    },
    clickEdit(item) {
      this.$store.commit(this.store + ".select", item.id);
      this.showModalTransaction = true;
    },
    checkCredit(item) {
      return item.credit_id == this.account_id;
    },
  },
};
</script>
