<template>
  <div>
    <CCard>
      <CCardHeader class="d-flex align-items-center">
        <TMessage :content="card.name" bold noTranslate />
        <TSpinner :loading="fetching" />
        <TButtonEdit
          @click="showModalCard = true"
          variant="outline"
          class="ml-auto"
        />
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol col="12" md="3">
            <CCallout color="info">
              <TMessage
                content="Accounting account"
                bold
                color="info"
                uppercase
              />
              <SMessageAccount :value="card.account_id" class="ml-1 h5" />
            </CCallout>
          </CCol>
          <CCol col="12" md="3">
            <CCallout color="info">
              <TMessage content="Bank" bold color="info" uppercase />
              <TMessage
                v-if="card.bank"
                :content="card.bank.name"
                class="h5"
                :truncate="1"
                noTranslate
              />
            </CCallout>
          </CCol>
          <CCol col="12" md="3">
            <CCallout color="info">
              <TMessage content="Currency" bold color="info" uppercase />
              <TMessage :content="card.currency_id" class="h5" noTranslate />
            </CCallout>
          </CCol>
          <CCol col="12" md="3">
            <CCallout color="primary">
              <TMessage content="Balance" bold color="primary" uppercase />
              <TMessageMoney
                :amount="card.balance"
                class="h5"
                :currency="card.currency_id"
              />
            </CCallout>
          </CCol>
        </CRow>
      </CCardBody>
      <CardModal :show.sync="showModalCard" :card="card" />
    </CCard>
    <TableTransactionsAccount
      v-if="card.account_id"
      class="mb-4"
      :account_id="card.account_id"
      store="accounting.transactions"
      :default_filter="{
        'filter[card_id]': id,
        'filter[debit_or_credit]': card.account_id,
      }"
      :key="`table-transactions-${id}`"
    />
  </div>
</template>

<script>
import CardModal from "./CardModal.vue";
import TableTransactionsAccount from "../../../components/TableTransactionsAccount.vue";
import { mapGetters } from "vuex";

export default {
  components: { CardModal, TableTransactionsAccount },
  data() {
    return {
      showModalCard: false,
    };
  },
  created() {
    this.$store.dispatch("accounting.cards.detail.fetch", this.id);
  },
  computed: {
    ...mapGetters({
      card: "accounting.cards.detail",
      fetching: "accounting.cards.detail.fetching",
    }),
    id() {
      return this.$route.params.id;
    },
  },
};
</script>
